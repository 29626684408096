import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { Card } from '@twilio-paste/card';
import { Stack } from '@twilio-paste/stack';
import { Alert } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Disclosure, DisclosureHeading, DisclosureContent } from '@twilio-paste/disclosure';
import { Link } from 'gatsby';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/inclusive-design/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{props.pageContext.frontmatter.description}</p>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`What is this guide?`}</h2>
        <p>{`Designing inclusive and accessible experiences may seem daunting. However, proper application of these practices can make your UX faster, more familiar, and available to the widest possible audience. It's also, quite simply, the right thing to do.`}</p>
        <p>{`This guide includes a lightly modified version of the excellent `}<a parentName="p" {...{
            "href": "https://github.com/Heydon/inclusive-design-checklist"
          }}>{`Inclusive Web Design Checklist`}</a>{` by `}<strong parentName="p">{`Heydon Pickering`}</strong>{`. It also has observations and notes from the Design Systems team.`}</p>
        <p>{`Note: This is not a comprehensive list. Product design and UX engineering are complex functions that can't be solved with a checklist. Instead, consider this as a way to spot areas of opportunity for your product.`}</p>
        <h2>{`How to use this guide`}</h2>
        <p>{`We recommend using this guide in the following ways:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Read through the guide once.`}</strong>{` We recommend starting with sections that relate to your role. However, reading the entire list will give you a richer understanding of inclusive design and accessibility.`}</li>
          <li parentName="ul"><strong parentName="li">{`Share this guide`}</strong>{` with your design, product, and engineering peers. Engage with them in a conversation about how we could carve some space in our day-to-day work to make our Twilio experiences a little more inclusive.`}</li>
          <li parentName="ul"><strong parentName="li">{`Revisit the guide at natural checkpoints`}</strong>{` of your product's design and development: we recommend doing so at the start of a project, before a UX review, and as a pre-launch checklist.`}</li>
          <li parentName="ul">{`Finally, `}<strong parentName="li">{`keep us accountable:`}</strong>{` Track our progress on our `}<a parentName="li" {...{
              "href": "https://github.com/twilio-labs/paste/discussions/561"
            }}>{`GitHub Discussions thread`}</a>{` as we apply these principles across this website.`}</li>
        </ul>
        <h2>{`General considerations`}</h2>
        <p>{`Baseline tips that are useful for all builders of web based experiences.`}</p>
        <Stack orientation="vertical" spacing="space30" mdxType="Stack">
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Alternative text for media content
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">Provide captions and transcripts for video content</ListItem>
        <ListItem mdxType="ListItem">Provide descriptive captions for figures</ListItem>
        <ListItem mdxType="ListItem">Provide transcripts for audio content</ListItem>
        <ListItem mdxType="ListItem">
          Provide alternative text for images that convey information{' '}
          <Anchor showExternal href="https://www.w3.org/WAI/tutorials/images/decision-tree/" mdxType="Anchor">
            (Learn more about this)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Ensure PDF content is accessible (include tags){' '}
          <Anchor showExternal href="https://webaim.org/techniques/acrobat/" mdxType="Anchor">
            (Learn more about this)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Avoid images of text, since this text that cannot be translated, selected, or understood by assistive
          technology
        </ListItem>
        <ListItem mdxType="ListItem">Provide alternatives and/or descriptions for complex visualizations</ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Other considerations
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">
          Remove potentially insensitive or uninclusive language, and use "singular they" pronouns in UIs. Consider
          using a service like{' '}
          <Anchor showExternal href="http://alexjs.com/" mdxType="Anchor">
            alexjs
          </Anchor>{' '}
          to catch any issues.
        </ListItem>
        <ListItem mdxType="ListItem">
          Make sure controls do not elicit unexpected or jarring behavior (For example, do not change behavior or
          context unexpectedly when a user{' '}
          <Anchor showExternal href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/consistent-behavior-receive-focus.html" mdxType="Anchor">
            changes focus
          </Anchor>{' '}
          or{' '}
          <Anchor showExternal href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/consistent-behavior-unpredictable-change.html" mdxType="Anchor">
            adjusts a control
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Make sure text and background colors contrast sufficiently{' '}
          <Anchor showExternal href="https://accessibility.blog.gov.uk/2016/06/17/colour-contrast-why-does-it-matter/" mdxType="Anchor">
            (Read more about why this matters)
          </Anchor>
        </ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
        </Stack>
        <h2>{`Considerations for product designers`}</h2>
        <p>{`The tips below are important considerations for the product design process. However, they are useful for engineers as well and must be considered at all stages of product development.`}</p>
        <Stack orientation="vertical" spacing="space30" mdxType="Stack">
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Form elements and composition
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">Give all form elements permanently visible labels</ListItem>
        <ListItem mdxType="ListItem">Give grouped form elements group labels</ListItem>
        <ListItem mdxType="ListItem">Place labels above form elements</ListItem>
        <ListItem mdxType="ListItem">Mark invalid fields clearly and provide associated error messages</ListItem>
        <ListItem mdxType="ListItem">Do not auto-focus form fields, on page load</ListItem>
        <ListItem mdxType="ListItem">Break up long and complex forms into discrete sections and/or screens</ListItem>
        <ListItem mdxType="ListItem">
          Make forms as short as possible. For example, consider offering shortcuts like autocompleting an address by
          using the postcode.
        </ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Text and typographic considerations
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">
          Make sure heading levels describe a logical section/subsection structure{' '}
          <Anchor showExternal href="https://webaim.org/techniques/semanticstructure/" mdxType="Anchor">
            (Read more about this)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">Translate / spell out acronyms the first time you use them</ListItem>
        <ListItem mdxType="ListItem">Employ well-balanced, highly legible fonts (not too complex or elaborate)</ListItem>
        <ListItem mdxType="ListItem">
          Do not use very thin font faces{' '}
          <Anchor showExternal href="http://www.telegraph.co.uk/science/2016/10/23/internet-is-becoming-unreadable-because-of-a-trend-towards-light/" mdxType="Anchor">
            (Read an article about why this matters)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">Underline links — at least in body copy</ListItem>
        <ListItem mdxType="ListItem">
          Avoid justified body text since it creates rivers of spacing that can be hard to read.{' '}
          <Anchor showExternal href="https://www.w3.org/TR/WCAG20-TECHS/F88.html" mdxType="Anchor">
            (Read more about why this matters)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Provide enough spacing between lines of text (`line-height`) to allow for readers to track text{' '}
          <Anchor showExternal href="https://www.w3.org/TR/WCAG20-TECHS/C21.html" mdxType="Anchor">
            (Read more about this)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Avoid all-caps text{' '}
          <Anchor showExternal href="https://github.com/humanmade/hm-pattern-library/issues/75" mdxType="Anchor">
            (Read more about this)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Ensure that content is written as clearly and simply as possible{' '}
          <Anchor showExternal href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/meaning-supplements.html" mdxType="Anchor">
            (Read more about why it matters)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Warn users of links that have unusual behaviors, like linking off-site, or loading a new tab
        </ListItem>
        <ListItem mdxType="ListItem">
          Make voice activation cues in your UI obvious with the use of text labels and / or visual affordances.
        </ListItem>
        <ListItem mdxType="ListItem">
          Do not mark up subheadings/straplines with separate heading elements{' '}
          <Anchor showExternal href="https://css-tricks.com/html-for-subheadings-and-headings/" mdxType="Anchor">
            (Read more about this)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">Begin long, multi-section documents with a table of contents</ListItem>
        <ListItem mdxType="ListItem">
          Make sure the purpose of a link is clearly described: "read more" vs. "read more about accessibility"
        </ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Visual design and visual affordances
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">Include only clear, meaningful animations</ListItem>
        <ListItem mdxType="ListItem">Do not rely on color as the only means of differentiation of visual elements</ListItem>
        <ListItem mdxType="ListItem">Make UI controls look like controls; give them strong perceived affordance</ListItem>
        <ListItem mdxType="ListItem">Avoid pure white or pure black shades</ListItem>
        <ListItem mdxType="ListItem">Indicate swipe gesture support clearly, and provide simple tap-based alternatives</ListItem>
        <ListItem mdxType="ListItem">Ensure primary calls to action are easy to recognize and reach</ListItem>
        <ListItem mdxType="ListItem">Use well-established, therefore recognizable, icons and symbols</ListItem>
        <ListItem mdxType="ListItem">Don't make users perform actions to reveal content unless completely necessary</ListItem>
        <ListItem mdxType="ListItem">If content is meant to be hidden, ensure it is properly hidden to all users</ListItem>
        <ListItem mdxType="ListItem">Inform the user when there are important changes to the application state</ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Other considerations
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">Use the same design patterns to solve the same problems</ListItem>
        <ListItem mdxType="ListItem">Label and describe the same things with the same terminology</ListItem>
        <ListItem mdxType="ListItem">Do not trigger "infinite scroll" by default; provide buttons to load more items</ListItem>
        <ListItem mdxType="ListItem">
          Avoid time constraints where possible; provide a clear warning and option to extend where not possible
        </ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
        </Stack>
        <h2>{`Considerations for UX and front-end engineers`}</h2>
        <p>{`While the tips below are geared towards developers, it is recommended that designers understand these concepts and have conversations about inclusive design with their engineering partners.`}</p>
        <Stack orientation="vertical" spacing="space30" mdxType="Stack">
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Performance considerations
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">
          Minify CSS and JS, and remove unused/redundant code{' '}
          <Anchor showExternal href="https://developers.google.com/speed/docs/insights/MinifyResources" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Compress raster images{' '}
          <Anchor showExternal href="https://www.html5rocks.com/en/tutorials/speed/img-compression/" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Optimize SVG path data.{' '}
          <Anchor showExternal href="https://jakearchibald.github.io/svgomg/" mdxType="Anchor">We recommend this tool for optimization.</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Make sure styles and scripts are not render-blocking{' '}
          Read more about <Anchor showExternal href="https://developers.google.com/web/fundamentals/performance/critical-rendering-path/render-blocking-css" mdxType="Anchor">blocking CSS</Anchor> and <Anchor showExternal href="https://developers.google.com/speed/docs/insights/BlockingJS" mdxType="Anchor">blocking JS</Anchor> here.
        </ListItem>
        <ListItem mdxType="ListItem">
          Install a service worker and cache all applicable assets{' '}
          <Anchor showExternal href="https://css-tricks.com/serviceworker-for-offline/" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">Lazy load large image assets</ListItem>
        <ListItem mdxType="ListItem">Subset fonts to just the characters needed</ListItem>
        <ListItem mdxType="ListItem">Use `srcset` to tailor images to devices and reduce bandwidth costs</ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
          <Disclosure mdxType="Disclosure">
  <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
    Responsive design considerations
  </DisclosureHeading>
  <DisclosureContent mdxType="DisclosureContent">
    <UnorderedList mdxType="UnorderedList">
      <ListItem mdxType="ListItem">Support "pinch zoom" (remove `user-scalable=no` if present)</ListItem>
      <ListItem mdxType="ListItem">
        Use content-based, not device-specific, media queries{' '}
        <Anchor showExternal href="http://bradfrost.com/blog/post/7-habits-of-highly-effective-media-queries/#content" mdxType="Anchor">
          (Read more about why this matters)
        </Anchor>
      </ListItem>
      <ListItem mdxType="ListItem">
        Provide large touch "targets" for interactive elements{' '}
        <Anchor showExternal href="http://www.bbc.co.uk/guidelines/futuremedia/accessibility/mobile/design/touch-target-size" mdxType="Anchor">
          (Read more about why this matters)
        </Anchor>
      </ListItem>
      <ListItem mdxType="ListItem">Ensure content is not obscured through zooming (no fixed widths)</ListItem>
      <ListItem mdxType="ListItem">Ensure the same content is available across different devices and platforms</ListItem>
    </UnorderedList>
  </DisclosureContent>
          </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Assistive technology considerations
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">
          Use screen reader and keyboard-accessible HTML{' '}
          <Anchor showExternal href="https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">Honor requests to remove animation via the `prefers-reduced-motion` media query</ListItem>
        <ListItem mdxType="ListItem">Do not recreate supported and expected browser behaviors with bespoke scripts</ListItem>
        <ListItem mdxType="ListItem">
          Support Windows high contrast mode (use images, not background images){' '}
          <Anchor showExternal href="http://adrianroselli.com/2012/08/css-background-images-high-contrast-mode.html" mdxType="Anchor">
            (Read more about why this matters)
          </Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Apply `alt="` or `aria-hidden="true"` to decorative images{' '}
          <Anchor showExternal href="https://www.w3.org/WAI/tutorials/images/decorative/" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">Use data tables (`table`) for data only, not visual layout purposes</ListItem>
        <ListItem mdxType="ListItem">Make scrollable elements focusable for keyboard users</ListItem>
        <ListItem mdxType="ListItem">Ensure keyboard focus order is logical regarding visual layout</ListItem>
        <ListItem mdxType="ListItem">Do not hijack standard scrolling behavior</ListItem>
        <ListItem mdxType="ListItem">Move focus between dialogs and the controls that invoked them</ListItem>
        <ListItem mdxType="ListItem">Provide status and error messages as WAI-ARIA live regions</ListItem>
        <ListItem mdxType="ListItem">Provide clear, unambiguous focus styles</ListItem>
        <ListItem mdxType="ListItem">Ensure states (pressed, expanded, invalid, etc) are communicated to assistive software</ListItem>
        <ListItem mdxType="ListItem">Match semantics to behavior for assistive technology users</ListItem>
        <ListItem mdxType="ListItem">Ensure disabled controls are not focusable</ListItem>
        <ListItem mdxType="ListItem">
          Provide a skip link if necessary{' '}
          <Anchor showExternal href="https://webaim.org/techniques/skipnav/" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">Make sure controls within hidden content are not focusable</ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Other considerations
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">Use relative units (`em`, `rem`, and `ch`), especially for font metrics</ListItem>
        <ListItem mdxType="ListItem">
          Do not include third parties that compromise user privacy{' '}
          <Anchor showExternal href="https://css-tricks.com/potential-dangers-of-third-party-javascript/" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Provide `title`s that name the site and the specific page{' '}
          <Anchor showExternal href="https://www.w3.org/TR/WCAG20-TECHS/G88.html" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Honour DNT (Do Not Track) header{' '}
          <Anchor showExternal href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">
          Provide a default language and use `lang="[ISO code]"` for subsections in different languages
        </ListItem>
        <ListItem mdxType="ListItem">
          Make sure all content belongs to a landmark element (`header`, `footer`, `nav`, `main`, etc)
        </ListItem>
        <ListItem mdxType="ListItem">Provide a `manifest.json` file for identifiable homescreen entries</ListItem>
        <ListItem mdxType="ListItem">Make sure data tables wider than their container can be scrolled horizontally</ListItem>
        <ListItem mdxType="ListItem">
          Make content easier to find and improve search results with structured data{' '}
          <Anchor showExternal href="https://developers.google.com/search/docs/guides/prototype" mdxType="Anchor">(Read more about why this matters)</Anchor>
        </ListItem>
        <ListItem mdxType="ListItem">Provide a print stylesheet (single column, with interactive content hidden)</ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
  </Stack>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      